/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="destination-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Destination Expert</h3>
      <div class="widget">
        <div class="destination-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="updateDestination"
            @reset.prevent="resetForm()"
          >
            <div>
              <div class="font-18 py-3">
                Information
                <span class="font-12">(* Required information)</span>
              </div>
              <!-- name -->
              <div>
                <b-form-group>
                  <label>Name*</label>
                  <b-input
                    v-model="$v.destination.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-name-live-feedback"
                    placeholder="Enter Name"
                  ></b-input>

                  <b-form-invalid-feedback id="input-name-live-feedback"
                    >Name is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- description -->
              <div class="pt-3">
                <b-form-group>
                  <label>Description*</label>
                  <br />
                  <b-form-textarea
                    id="description1"
                    :other_options="options"
                    :state="validateState('description')"
                    v-model="destination.description"
                    aria-describedby="input-content-live-feedback"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>

                  <b-form-invalid-feedback id="input-content-live-feedback"
                    >Description is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- location -->
              <div class="pt-3">
                <b-form-group>
                  <label>Location</label>
                  <br />
                  <b-input v-model="destination.location" placeholder="Enter Location"></b-input>
                </b-form-group>
              </div>
              <!-- mobile -->
              <div class="pt-3">
                <b-form-group>
                  <label>Mobile No*</label>
                  <br />
                  <b-input
                    v-model="$v.destination.mobile.$model"
                    :state="validateState('mobile')"
                    aria-describedby="input-mobile-live-feedback"
                    type="number"
                    placeholder="Enter Mobile No"
                  ></b-input>

                  <b-form-invalid-feedback id="input-mobile-live-feedback"
                    >Mobile No. is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- attachment -->
              <div class="pt-2">
                <b-form-group>
                  <label>Image*</label>
                  <br />
                  <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput1.click()"
                    >Upload</b-button
                  >
                  <input
                    ref="fileInput1"
                    type="file"
                    accept="image/jpeg"
                    multiple
                    style="display:none;"
                    @change="onCardChange($event.target.name, $event.target.files)"
                  />
                  <div
                    v-if="
                      !$v.destination.image.required &&
                        $v.destination.image.$error &&
                        $v.destination.image.$touch
                    "
                    class="red error-text py-1"
                  >
                    Image is required
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-row gutters>
                  <b-col md="2" cols="4" v-for="(item, key) in img" class="my-2" :key="item">
                    <div>
                      <div>
                        <div class="icon-section">
                          <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="red font-12"
                            @click="removeCard(key)"
                          />
                        </div>
                        <img :src="item" alt="your image" class="img-fluid images ml-3" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">{{ message }}</div>
            <div class="d-flex justify-content-center pt-3">
              <div>
                <b-button variant="success" type="submit">Update</b-button>
              </div>
              <div>
                <b-button variant="danger" type="reset" class="ml-5">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    headerCustom
  },
  data() {
    return {
      destination: {
        name: "",
        description: "",
        mobile: "",
        location: "",
        image: []
      },
      img: [],
      destId: "",
      showError: false,
      message: ""
    };
  },
  validations: {
    destination: {
      name: {
        required
      },
      description: {
        required
      },
      mobile: {
        required
      },
      image: {
        required
      }
    }
  },
  created() {
    this.destId = this.$route.params.id;
    if (this.destId) {
      this.getOneDestination();
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.destination[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.$router.push({ name: "viewDestination" });
    },
    removeCard(image) {
      this.img.pop(image);
    },
    onCardChange(coverName, coverList) {
      if (this.img.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
      } else {
        this.coverFile = coverList[0];
        const coverSize = this.coverFile.size / 1024;
        if (coverSize > 1000) {
          //cover ka 500kb
          this.$toaster.error("File Size should not be greater than 1mb.");
          return;
        }
        if (this.coverFile.type == "image/jpeg" || this.coverFile.type == "image/jpg") {
          const fd = new FormData();
          fd.append("file", this.coverFile, this.coverFile.name);
          service.awsDestinationImageUpload(fd, awsResponse => {
            if (awsResponse.status === 200) {
              const predictionResults = awsResponse.data;
              this.s3URL = predictionResults.file;
              this.img.push(this.s3URL);
              this.destination.image = this.img;
            } else {
              this.$toaster.error("Image Not Saved");
            }
          });
        } else {
          this.$toaster.error("Image should be in JPEG format only");
        }
      }
    },
    getOneDestination() {
      service.getOneDestination(this.destId, result => {
        this.destination = result.data;
        this.img = result.data.image;
      });
    },
    updateDestination() {
      this.$v.destination.$touch();
      if (this.$v.destination.$anyError) {
        return;
      } else {
        return new Promise((resolve, reject) => {
          service.updateDestination(this.destination, this.destId, resultData => {
            if (resultData) {
              this.$toaster.success("Destination Expert updated");
              this.$router.push({ name: "viewDestination" });
              resolve();
            } else {
              reject();
            }
          });
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.destination-section {
  .destination-content {
    padding: 20px 20px;
  }
}
.red {
  color: red;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
